.roster-pdf-view {
  .toolbar {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    height: 48px;
    display: flex;
    padding: 0px 8px;
    align-items: center;
    background-color: #ffffff;
    justify-content: space-between;
    box-shadow: 0px 1px 0px #dbdbdb;

    .cancel-button {
      position: relative;
      top: auto;
      left: auto;
      width: 70px;
      z-index: 1;
      border: none;
      font-size: 12px;
      color: #2a2a2a;
      line-height: 100%;
      padding: 9px 10px;
      border-radius: 4px;
      background-color: #f2f2f2;
    }

    .print-button {
      display: flex;
      height: 32px;
      cursor: pointer;
      padding: 0px 6px;
      border-radius: 4px;
      align-items: center;
      background: #f2f2f2;

      img {
        width: 20px;
        height: 18px;
      }
    }
  }

  .content-frame {
    flex: 1 1;

    .centered-frame {
      margin: 48px auto;

      .profile-frame {
        position: relative;
        display: flex;
        margin-bottom: 24px;
        align-items: center;
        flex-direction: column;

        .company-logo {
          height: 40px;
          margin-bottom: 28px;

          img {
            height: 100%;
          }
        }

        .avatar-frame {
          display: flex;
          margin-bottom: 32px;
          align-items: center;
          flex-direction: column;

          .avatar {
            width: 100px;
            height: 100px;
            display: flex;
            overflow: hidden;
            margin-bottom: 20px;
            border-radius: 50%;
            align-items: center;
            background: #ff5a5f;
            justify-content: center;

            img {
              width: 100%;
              height: 100%;
            }

            .initials {
              color: white;
              font-size: 32px;
            }
          }

          .full-name {
            font-size: 24px;
            font-weight: bold;
            line-height: 130%;
          }
        }

        .details-frame {
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          border-top: 1px dashed #919191;

          .detail-frame {
            width: 30%;
            padding: 26px 0px;

            .detail-label {
              font-size: 14px;
              line-height: 130%;
            }
          }
        }

        .detail-creds-frame {
          width: 100%;
          display: flex;
          justify-content: space-between;
          border-top: 1px dashed #919191;

          .detail-cred-frame {
            width: 32.5%;

            .detail-cred-header {
              font-size: 14px;
              line-height: 130%;
              margin: 30px 0px 19px 0px;
              text-transform: uppercase;
            }

            .detail-cred-list-view {
              margin: 0px;
              padding: 0px;
              list-style: none;

              .detail-cred-cell {
                margin-bottom: 17px;

                .cell-label {
                  font-size: 14px;
                }
              }

              .detail-cred-cell:last-child {
                margin: 0px;
              }
            }
          }
        }
      }

      .results-frame {
        margin-bottom: 48px;

        .header {
          font-size: 24px;
          font-weight: bold;
          line-height: 130%;
          margin-bottom: 5px;
          padding-bottom: 12px;
          text-transform: capitalize;
          border-bottom: 1px dashed #919191;
        }

        .results-list-view {
          margin: 0px;
          padding: 0px;
          list-style: none;

          .result-cell,
          .result-cell-breakable {
            display: flex;
            padding: 10px;
            flex-direction: column;

            .flex-frame {
              display: flex;
              align-items: center;

              .progress-bar-view {
                margin-left: 20px;
              }
            }

            .flex-frame.column {
              min-width: 95px;
              flex-direction: column;
              align-items: flex-start;
            }

            .flex-frame.column.score {
              align-items: flex-end;
            }

            .top-section {
              display: flex;
              align-items: center;
              justify-content: space-between;

              // print break
              position: relative;
              break-inside: avoid;
              -webkit-break-inside: avoid;

              .cell-label {
                font-size: 16px;
              }

              .bold {
                font-size: 16px;
              }

              .score {
                text-align: right;
              }

              .margin {
                margin-right: 5px;
              }
            }

            .reference-frame {
              margin: 12px 0px;
              padding: 15px 19px;
              border-radius: 4px;
              box-sizing: border-box;
              border: 1px solid #dbdbdb;

              .flex-frame {
                margin-bottom: 7px;

                .key {
                  flex: 0 0 33%;
                  margin-right: 1%;
                  text-transform: capitalize;
                }

                .cell-label {
                  font-size: 14px;
                }
              }

              .flex-frame.full {
                margin: 0px;
                flex-direction: column;
                align-items: flex-start;

                .key {
                  flex: 0 0 100%;
                  margin-bottom: 7px;
                }
              }
            }

            .result-details-frame {
              margin: 20px 10px;

              .header {
                border: none;
                font-size: 14px;
                box-shadow: none;
                font-weight: bold;
                line-height: 20px;
                margin-bottom: 5px;
                text-transform: uppercase;
              }

              .answers-list-view {
                margin: 0px;
                padding: 0px;
                list-style: none;

                .answer-cell {
                  padding: 14px 20px;
                  border-radius: 6px;
                  margin-bottom: 12px;
                  box-sizing: border-box;
                  border: 1px solid #dbdbdb;

                  // print break
                  position: relative;
                  break-inside: avoid;
                  -webkit-break-inside: avoid;

                  .cell-label {
                    font-size: 16px;
                    line-height: 130%;
                    white-space: normal;
                    word-wrap: break-word;
                  }

                  .flex-frame {
                    display: flex;
                    justify-content: space-between;
                  }

                  .flex-frame.horizontal {
                    flex-direction: column;

                    .title {
                      margin: 10px 0px;
                    }
                  }
                }

                .question-cell.correct {
                  background: #ebf8ed;
                }
              }

              .categories-list-view {
                margin: 20px;
                padding: 0px;
                list-style: none;

                .category-cell {
                  border-radius: 6px;
                  margin-bottom: 12px;
                  border: 1px solid #dbdbdb;

                  .flex-frame {
                    height: 60px;
                    display: flex;
                    padding: 0px 2%;
                    align-items: center;
                    justify-content: space-between;

                    .cell-label {
                      font-size: 16px;
                    }
                  }

                  .sections-list-view {
                    margin: 0px;
                    padding: 0px;
                    list-style: none;
                    border-top: 1px solid #dbdbdb;

                    .flex-frame {
                      padding: 0px 4%;
                      border-bottom: 1px solid #dbdbdb;
                    }

                    .section-cell {
                      padding: 0px;

                      .questions-list-view {
                        margin: 0px;
                        padding: 0px;
                        list-style: none;

                        .question-cell {
                          height: 60px;
                          display: flex;
                          padding: 0px 6%;
                          align-items: center;
                          justify-content: space-between;
                          border-bottom: 1px solid #dbdbdb;

                          break-inside: avoid;
                          -webkit-break-inside: avoid;

                          .answer {
                            width: 36px;
                            height: 36px;
                            display: flex;
                            color: white;
                            flex: 0 0 36px;
                            font-size: 24px;
                            border-radius: 6px;
                            align-items: center;
                            justify-content: center;
                          }
                        }
                      }
                    }

                    .section-cell:last-child {
                      .question-cell:last-child {
                        border: none;
                      }
                    }
                  }
                }
              }
            }

            .result-image-frame {
              margin: 10px;
              display: flex;
              justify-content: center;

              img {
                max-width: 100%;
              }

              embed {
                width: 100%;
                height: 500px;
                max-width: 100%;
              }
              .react-pdf__Page__canvas {
                margin: 0 auto;
                width: 90% !important;
                height: 90% !important;
              }
            }
          }

          .result-cell:nth-child(even),
          .result-cell-breakable:nth-child(even) {
            background: #f6f8f8;
          }

          .result-cell.summary,
          .result-cell-breakable.summary {
            .top-section {
              margin: 0px;

              a:link,
              a:visited {
                color: #ff5a5f;
              }

              .attachment-link {
                margin-bottom: 5px;

                div {
                  font-size: 14px;
                  line-height: 130%;
                }
              }

              .cell-label {
                font-size: 16px;
              }
            }

            .result-details-frame {
              display: none;
            }
          }
        }
      }
    }
  }

  .page-footer {
    position: fixed;
    left: 0px;
    right: 0px;
    bottom: 0px;
    height: 45px;
    display: none;
    background: white;

    .centered-frame {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .flex-frame {
        display: flex;
        align-items: center;

        .footer-label {
          font-size: 16px;
          line-height: 130%;
        }

        img {
          height: 18px;
          margin-left: 6px;
        }
      }

      .footer-link {
        font-size: 16px;
        color: #0daae4;
      }
    }
  }
}

table {
  width: 100%;
  height: 100%;

  tbody {
    tr {
      td {
        position: relative;
        width: 100%;
        height: 100%;
      }
    }
  }
}

@media print {
  @page {
    margin: 5mm;
  }

  .roster-pdf-view {
    .toolbar {
      display: none;
    }

    .content-frame {
      position: initial;
      top: 0px;

      .centered-frame {
        margin-top: 0px;

        .profile-frame {
          position: relative;
          break-inside: avoid;
          -webkit-break-inside: avoid;

          .details-frame {
            .detail-creds-frame {
              height: 350px;
            }

            .detail-creds-frame.chrome {
              height: 425px;
            }
          }
        }
        .hide {
          page-break-before: always;
          page-break-after: always;
        }
        .results-frame {
          position: relative;
          padding-top: 20px;
          page-break-before: always;
          break-inside: avoid;
          -webkit-break-inside: avoid;

          .header {
            page-break-before: always;
          }
          .result-cell, {
            break-inside: avoid;
            -webkit-break-inside: avoid;
          }
          .results-list-view {
            :not(:first-child).result-cell-breakable:not(.summary) {
              margin-top: 600px;
            }
          }
          .result-cell,
          .result-cell-breakable {
            padding: 10px !important;
            display: block !important;

            .result-image-frame,
            .reference-frame {
              position: relative;
              page-break-after: always;

              img {
                max-height: 400px;
              }
            }

            .result-image-frame.safari {
              img {
                max-height: 800px;
              }
            }

            .result-details-frame {
              margin-left: 0px !important;
              margin-right: 0px !important;

              .categories-list-view {
                margin-left: 0px !important;
                margin-right: 0px !important;

                .category-cell {
                  break-inside: avoid;
                  -webkit-break-inside: avoid;
                  .flex-frame {
                    height: 48px !important;
                  }

                  .cell-label {
                    font-size: 12px !important;
                  }

                  .section-cell {
                    .flex-frame {
                      break-inside: avoid !important;
                      -webkit-break-inside: avoid !important;
                    }

                    .question-cell {
                      min-height: 48px !important;

                      .question {
                        font-size: 12px !important;
                      }

                      .answer {
                        width: 27px !important;
                        height: 27px !important;
                        flex: 0 0 27px !important;
                        font-size: 18px !important;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .page-footer {
      display: initial;
    }
  }

  .page-footer,
  .footer-space {
    height: 18px;
  }

  .page-footer {
    position: fixed;
    bottom: 0;
    background: white;
  }
}
