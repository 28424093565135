.dashboard-documents-view {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  -webkit-touch-callout: none; // iOS Safari
  -webkit-user-select: none; // Safari
  -khtml-user-select: none; // Konqueror HTML
  -moz-user-select: none; //Old versions of Firefox
  -ms-user-select: none; //Internet Explorer/Edge
  user-select: none; //Non-prefixed version, currently supported by Chrome, Opera and Firefox

  .stats-frame {
    width: 100%;
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 86px;
    padding: 30px 0px;
    border-bottom: 1px solid #dbdbdb;

    .centered-frame {
      display: flex;
      justify-content: space-between;

      .stat-frame {
        width: 30%;

        .header {
          font-size: 14px;
          color: #919191;
          line-height: 18px;
          text-align: center;
        }

        .stat-label {
          margin-top: 7px;
          font-size: 48px;
          color: #919191;
          line-height: 61px;
          text-align: center;
        }

        .stat-label.black {
          color: #484848;
        }

        .stat-label.teal {
          color: #008489;
        }
      }

      .stat-frame.middle {
        border-left: 1px solid #e8e8e8;
        border-right: 1px solid #e8e8e8;
      }
    }
  }

  .filter-frame {
    width: 100%;
    height: 48px;
    border-bottom: 1px solid #e8e8e8;

    .centered-frame {
      position: relative;

      .search {
        float: left;
        margin-top: 7.4px;
        margin-left: -1.5px;
        font-size: 12px;
        font-weight: 600;
        color: #484848;
        line-height: 15px;
        background: #ffffff;
        border: 1px solid #dbdbdb;
        box-sizing: border-box;
        border-radius: 4px;
        width: 20%;
        height: 32px;
        padding: 7px 10px;
        resize: none;
        white-space: pre-line;
      }

      .magnifying-glass {
        position: absolute;
        top: 14px;
        left: 6px;
        width: 16px;
        height: 16px;
      }

      .create-test-button {
        float: right;
        border: none;
        padding: 14.5px 0px 0 0;
        font-size: 12px;
        background: none;
        line-height: 15px;
      }
    }
  }

  .secondary-toolbar-frame {
    height: 48px;
    box-shadow: 0px 1px 0px #dbdbdb;

    .centered-frame {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .create-test-button {
        margin: 0px;
        border: none;
        padding: 0px;
        font-style: 12px;
        background: none;
      }
    }
  }

  .empty-label {
    opacity: 0.5;
    font-size: 21px;
    font-style: italic;
  }

  .tests-table-view {
    position: absolute;
    top: 192px;
    left: 0px;
    right: 0px;
    bottom: 0px;

    .table-headers {
      height: 30px;
      background: #f2f2f2;
      border-top: 1px solid #e8e8e8;
      border-bottom: 1px solid #e8e8e8;

      .centered-frame {
        .table-header {
          position: relative;
          top: 7.5px;
          float: left;
          width: 8%;
          font-size: 12px;
          overflow: hidden;
          line-height: 15px;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        .title {
          width: 24%;
        }

        .title.small {
          width: 19%;
        }

        .title.long {
          width: 35%;
        }

        .expiration {
          width: 20%;
        }

        .date {
          width: 14%;
        }

        .status {
          width: 15%;
        }

        .list {
          width: 14%;
        }
      }
    }

    .tests-list-view {
      position: absolute;
      top: 32px;
      left: 0px;
      right: 0px;
      bottom: 0px;
      margin: 0px;
      padding: 0px;
      list-style: none;

      .test-cell {
        height: 36px;
        cursor: pointer;

        .centered-frame {
          position: relative;
          height: 36px;

          .cell-content-frame {
            position: relative;
            z-index: 0;
            height: 100%;

            .cell-label {
              position: relative;
              top: 9.5px;
              width: 7%;
             // margin-right: 1%;
              float: left;
              font-size: 12px;
              text-transform: capitalize;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }

            .title {
              width: 24%;
              font-weight: 900;
            }

            .title.small {
              width: 18%;
            }

            .title.long {
              width: 34%;
            }

            .date{
                width:14%;
            }

            .expiration {
              width: 20%;
            }

            .status {
              width: 15%;
            }
          }
        }

        .expander-icon {
          position: absolute;
          top: 0px;
          right: 0px;
          width: 5%;
          height: 100%;
          display: flex;
          cursor: pointer;
          align-items: center;
          justify-content: center;

          img {
            width: 11px;
            height: 3px;
          }
        }
      }

      .test-cell.disabled {
        cursor: not-allowed !important;
        background: #dddddd !important;
        border-bottom: 1px solid #e8e8e8;

        .cell-label {
          color: #919191 !important;
        }
      }

      .test-cell:nth-child(even) {
        background: #fafafa;
      }
    }
  }
}

.dashboard-cell-menu {
  position: relative;
  padding: 5px 0px;
  right: -10px;
  width: 134px;
}
