.roster-test-view {
    .toolbar {
        display: flex;
        padding: 8px 10px;
        align-items: center;
        box-sizing: border-box;
        justify-content: space-between;

        .cancel-button {
            position: relative;
            top: auto;
            left: auto;
        }

        .print-button {
            display: flex;
            height: 32px;
            cursor: pointer;
            padding: 0px 6px;
            border-radius: 4px;
            align-items: center;
            background: #F2F2F2;

            img {
                width: 20px;
                height: 18px;
            }
        }
    }

    .content-frame {
        .centered-frame {
            display: flex;
            flex-direction: column;

            .logo-frame {
                flex: 0 0 107px;
                display: flex;
                margin-top: 60px;
                padding-bottom: 45px;
                align-items: center;
                justify-content: space-between;
                border-bottom: 1px solid #DBDBDB;

                .company-logo,
                .una-logo {
                    height: 46px;

                    img {
                        height: 100%;
                    }
                }

                .color-vision-logo {
                    height: 46px;

                    img {
                        height: 100%;
                    }
                }

                .flex-frame {
                    display: flex;
                    align-items: center;
                    flex-direction: column;

                    .una-logo {
                        height: 20px;
                    }

                    .evp-badge-logo {
                        height: 72px;
                        margin-bottom: 15px;

                        img {
                            height: 100%;
                        }
                    }
                }
            }

            .profile-frame {
                display: flex;
                flex: 0 0 91px;
                padding: 24px 0px 50px 0px;
                justify-content: space-between;

                .section-frame {
                    width: 30%;

                    .section-header,
                    .section-subvalue {
                        font-size: 16px;
                        line-height: 20px;
                    }

                    .section-value {
                        font-size: 24px;
                        font-weight: 600;
                        margin: 10px 0px 7px 0px;
                    }

                    .flex-frame {
                        display: flex;

                        .score-label {
                            margin-left: 5px;
                        }
                    }
                }
            }

            .categories-frame {
                transition: 0.3s;
                overflow: hidden;
                margin-bottom: 48px;

                .header-frame {
                    height: 53px;
                    display: flex;
                    overflow: hidden;
                    padding: 0px 20px;
                    border-radius: 6px;
                    margin-bottom: 20px;
                    align-items: center;
                    background: #F2F2F2;
                    justify-content: space-between;
                    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));

                    .header {
                        font-size: 24px;
                    }

                    .collapse-button {
                        cursor: pointer;
                        font-size: 12px;
                        background: none;
                        color: #E84C60;
                        font-weight: bold;
                    }
                }

                .categories-list-view {
                    margin: 0px;
                    padding: 0px;
                    display: flex;
                    overflow: hidden;
                    list-style: none;
                    border-radius: 6px;
                    flex-direction: column;
                    border: 1px solid #DBDBDB;

                    .category-cell {
                        height: 60px;
                        display: flex;
                        padding: 0px 20px;
                        align-items: center;
                        justify-content: space-between;
                        border-bottom: 1px solid #DBDBDB;

                        .cell-label {
                            font-size: 16px;
                            line-height: 100%;
                        }
                    }

                    .category-cell:last-child {
                        border: none;
                    }
                }
            }

            .rule-label {
                text-align: center;
                font-style: italic;
                margin-bottom: 50px;
            }

            .empty-frame {
                position: relative;
                flex: 1 0 45px;

                .layout-center {
                    width: 90%;
                    max-width: 640px;

                    .empty-label {
                        text-align: center;
                    }
                }
            }

            .questions-frame {
                .header {
                    font-size: 16px;
                    line-height: 20px;
                    margin-bottom: 15px;
                    text-transform: uppercase;
                }

                .questions-list-view {
                    margin: 0px;
                    padding: 0px;
                    list-style: none;

                    .question-cell {
                        padding: 14px 20px;
                        border-radius: 6px;
                        margin-bottom: 12px;
                        box-sizing: border-box;
                        border: 1px solid #DBDBDB;

                        .question-label {
                            font-size: 16px;
                            line-height: 130%;
                            white-space: normal;
                            word-wrap: break-word;
                        }

                        .flex-frame {
                            display: flex;
                            justify-content: space-between;
                        }

                        .flex-frame.horizontal {
                            flex-direction: column;

                            .title {
                                margin: 10px 0px;
                            }
                        }
                    }

                    .question-cell.correct {
                        background: #ebf8ed;
                    }
                }
            }
        }
    }
}

@media print {
    .roster-test-view {
        margin: 0px -20px;

        .toolbar {
            display: none !important;
        }

        .content-frame {
            position: initial;
            min-height: 800px;

            .logo-frame {
                margin-top: 30px;
            }

            .profile-frame {
                .section-frame {
                    .section-header,
                    .section-subvalue {
                        font-size: 14px;
                    }

                    .section-value {
                        font-size: 18px;
                    }
                }
            }

            .categories-frame {
                .header-frame {
                    height: 20px !important;
                    padding: 0px !important;
                    filter: none !important;
                    background: none !important;

                    .header {
                        font-size: 16px !important;
                        text-transform: uppercase;
                    }
                }


                .collapse-button {
                    display: none;
                }
            }

            .questions-frame {
                .questions-list-view {
                    .question-cell {
                        page-break-inside: avoid;
                    }
                }
            }

            .empty-frame {
                position: relative;
                top: 200px;

                .layout-center {
                    width: 600px;
                    // height: 500px;
                }
            }
        }
    }
    .no-print{
      display: none;
    }
}
