.org-reporting-view {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    .reporting-toolbar {
        position: -webkit-sticky;
        position: sticky;
        top: 0px;
        z-index: 1;
        height: auto;
        display: flex;
        flex: 0 0 48px;
        background: white;
        align-items: center;
        justify-content: center;
        border-bottom: 1px solid #DBDBDB;

        .dates-frame {
            display: flex;
            align-items: center;

            .date-frame {
                position: relative;
                z-index: 1;
                width: 250px;

                .date-header {
                    color: #919191;
                    font-size: 12px;
                    text-transform: capitalize;
                }

                .date-picker {
                    width: 100%;
                    display: flex;
                    justify-content: space-between;

                    .days-menu,
                    .months-menu {
                        width: 29%;
                    }

                    .years-menu {
                        width: 39%;
                    }

                    .css-6yg5gq-indicatorContainer {
                        padding: 6px 3px;
                    }
                }
            }

            .seperator {
                font-size: 12px;
                margin: 0px 10px;
            }
        }

        .button-frame {
            position: absolute;
            right: 0px;

            .export-button, .run-report-button {
                z-index: 1;
                color: white;
                font-size: 12px;
                font-weight: bold;
                padding: 8px 20px;
                margin-right: 10px;
                border-radius: 4px;
                background: #FF5A5F;
                border: 1px solid #FF5A5F;
            }

            .export-button {
                color: #FF5A5F;
                background: white;
                text-decoration: none;
            }
        }
    }

    .dashboard-frame {
        flex: 1 1;
        display: flex;
        flex-direction: column;

        .stats-frame {
            flex: 0 0 157px;
            border-bottom: 1px solid #DBDBDB;

            .centered-frame {
                display: flex;
                flex-wrap: wrap;
                margin: 48px auto 19px auto;
                justify-content: space-between;

                .stat-frame {
                    width: 32%;
                    margin-bottom: 30px;

                    .stat-header {
                        font-size: 18px;
                    }

                    .stat-value {
                        font-size: 24px;
                        line-height: 30px;
                    }

                    .view-breakdown-button {
                        padding: 0px;
                        font-size: 12px;
                        background: none;
                        color: #FF5A5F;
                        font-weight: bold;
                        line-height: 15px;
                        margin: 4px 0px 0px 0px;
                    }
                }
            }
        }

        .breakdown-frame {
            flex: 1 1;
            display: flex;
            overflow: hidden;
            flex-direction: column;

            .empty-label {
                flex: 1 1;
                display: flex;
                color: #919191;
                min-height: 500px;
                font-style: italic;
                align-items: center;
                justify-content: center;
            }

            .table-headers {
                height: auto;
                flex: 0 0 30px;

                .centered-frame {
                    margin: 0px auto;

                    .table-header {
                        margin-right: 1%;
                        text-transform: capitalize;
                    }

                    .name {
                        width: 29%;
                    }

                    .content, .date, .expirations {
                        width: 13%;
                    }
                }
            }

            .users-list-view {
                margin: 0px;
                padding: 0px;
                list-style: none;

                .user-cell {
                    height: 40px;
                    overflow: initial;

                    .centered-frame {
                        height: 100%;
                        display: flex;
                        margin: 0px auto;
                        align-items: center;

                        .cell-label {
                            font-size: 12px;
                            margin-right: 1%;
                        }

                        .name {
                            width: 29%;
                        }

                        .content, .date, .expirations {
                            width: 13%;
                        }

                        .expirations {
                            display: flex;

                            .expirations-frame {
                                position: relative;

                                .expirations-list-view {
                                    display: none;
                                }

                                .expiration-label {
                                    position: relative;
                                    left: -10px;
                                    width: 24px;
                                    height: 25px;
                                    display: flex;
                                    border-radius: 50%;
                                    align-items: center;
                                    justify-content: center;
                                }
                            }

                            .expirations-frame:hover {
                                .expiration-label {
                                    color: #FF5A5F;
                                    background: #F2F2F2;
                                }

                                .expirations-list-view {
                                    position: absolute;
                                    top: 28px;
                                    left: -117px;
                                    z-index: 10;
                                    margin: 0px;
                                    padding: 0px;
                                    display: initial;
                                    min-width: 234px;
                                    overflow: hidden;
                                    list-style: none;
                                    border-radius: 4px;
                                    background: white;
                                    box-shadow: 0px 0px 12px rgba(72, 72, 72, 0.2);

                                    .document-cell {
                                        padding: 4.5px 10px;
                                        box-sizing: border-box;

                                        .cell-label {
                                            font-size: 12px;
                                            line-height: 150%;
                                            text-transform: capitalize;
                                        }

                                        .flex-frame {
                                            display: flex;
                                        }
                                    }

                                    .document-cell:first-child {
                                        padding-top: 7px;
                                    }

                                    .document-cell:last-child {
                                        padding-bottom: 7px;
                                    }
                                }
                            }
                        }
                    }
                }

                .user-cell:nth-child(even) {
                    background: #FAFAFA;
                }
            }
        }
    }

    .timer-breakdown-pane {
        .modal-pane-content {
            width: 90%;
            min-width: 0px;
            max-width: 800px;

            .modal-content-frame {
                height: 100%;
                display: flex;
                flex-direction: column;

                .modal-toolbar-frame {
                    display: flex;
                    flex: 0 0 48px;
                    padding: 8px 10px;
                    align-items: center;
                    box-sizing: border-box;
                    border-bottom: 1px solid #DBDBDB;

                    .cancel-button {
                        position: relative;
                        z-index: 1;
                        font-size: 12px;
                        color: #2A2A2A;
                        font-weight: bold;
                        line-height: 15px;
                        padding: 9px 15px;
                        border-radius: 4px;
                        background: #F2F2F2;
                        box-sizing: border-box;
                    }

                    .modal-toolbar {
                        position: absolute;
                        left: 0px;
                        right: 0px;
                        z-index: 0;
                        font-size: 16px;
                        font-weight: bold;
                        line-height: 20px;
                        text-align: center;
                    }
                }

                .stat-frame {
                    flex: 0 0 154px;
                    padding: 45px 0px;
                    box-sizing: border-box;

                    .centered-frame {
                        margin: auto;

                        .stat-header {
                            font-size: 16px;
                            line-height: 20px;
                            margin-bottom: 10px;
                        }

                        .stat-value {
                            font-size: 24px;
                            font-weight: bold;
                            line-height: 30px;
                        }
                    }
                }

                .breakdown-table-view {
                    flex: 1 1;
                    display: flex;
                    flex-direction: column;
                    border-top: 1px solid #DBDBDB;

                    .table-headers {
                        flex: 0 0 30px;
                        
                        .centered-frame {
                            margin: auto;
                            display: flex;

                            .table-header {
                                flex: 1 1;
                                text-transform: capitalize;
                            }

                            .time {
                                flex: 0 0 120px;
                                margin-left: 10px;
                            }
                        }
                    }

                    .breakdown-list-view {
                        margin: 0px;
                        padding: 0px;
                        list-style: none;

                        .breakdown-cell {
                            height: 36px;

                            .centered-frame {
                                height: 100%;
                                margin: auto;
                                display: flex;
                                align-items: center;

                                .cell-label {
                                    flex: 1 1;
                                    font-size: 12px;
                                }

                                .csv {
                                    color: #FF5A5F !important;
                                }

                                .time {
                                    flex: 0 0 120px;
                                    margin-left: 10px;
                                }
                            }
                        }

                        .breakdown-cell:nth-child(even) {
                            background: #FAFAFA;
                        }
                    }
                }
            }
        }
    }
}