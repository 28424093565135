.document-read-view {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    display: flex;
    flex-direction: column;

    .toolbar {
        display: flex;
        flex: 0 0 48px;
        padding: 0px 10px;
        align-items: center;
        justify-content: space-between;
        box-shadow: 0px 1px 0px #DBDBDB;

        .back-button,
        .save-button {
            position: relative;
            top: auto;
            left: auto;
            width: 70px;
            z-index: 1;
            border: none;
            font-size: 12px;
            color: #2A2A2A;
            line-height: 100%;
            padding: 9px 10px;
            border-radius: 4px;
            text-transform: capitalize;
            background-color: #F2F2F2;
        }

        .bg-una {
            margin-right: 10px;
        }

        .bg-disabled {
            cursor: not-allowed;
        }

        .page-title {
            position: absolute;
            left: 0px;
            right: 0px;
            text-align: center;
            text-transform: capitalize;
        }

        .button-frame {
            display: flex;
            
            .save-button {
                color: white;
            }
        }

        .mobile-button {
            position: relative;
            z-index: 2;
            width: 32px;
            height: 32px;
            display: flex;
            cursor: pointer;
            border-radius: 4px;
            justify-content: center;

            .mobile-button-icon {
                width: 14px;
                height: 14px;
                margin-top: 6px;

                img {
                    width: 100%;
                    height: 100%;
                }
            }
        }

        .mobile-button.delete {
            margin-right: 8px;

            .mobile-button-icon {
                width: 20px;
                height: 20px;
            }
        }

        .mobile-button.edit {
            .mobile-button-icon {
                width: 20px;
                height: 20px;
            }
        }

        .mobile-button.save {
            .mobile-button-icon {
                width: 18px;
                height: 16px;

                img {
                    width: 17.59px;
                    height: 13.41px;
                }
            }
        }
    }

    .content-frame {
        flex: 1 0;

        .document-frame {
            display: flex;
            width: 90%;
            flex-wrap: wrap;
            max-width: 960px;
            margin: 30px auto 0px auto;
            justify-content: space-between;

            .header {
                width: 100%;
                font-size: 18px;
                font-weight: bold;
                margin-bottom: 30px;
                text-transform: capitalize;
            }

            .flex-frame {
                width: 100%;
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
            }

            .read-value-frame {
                width: 30%;
                height: 60px;
                margin-bottom: 42px;

                .value-label {
                    font-size: 12px;
                    line-height: 160%;
                }

                .value-label.italic {
                    font-style: italic;
                }
            }

            .read-value-frame.empty {
                visibility: hidden;
            }

            .document-images-frame {
                width: 100%;
                display: flex;
                height: 200px;
                justify-content: flex-start;

                .document-image {
                    width: 33%;
                    height: 100%;
                    display: flex;
                    justify-content: center;

                    img {
                        max-width: 90%;
                    }
                }
            }

            .document-image {
                width: 100%;
                display: flex;
                justify-content: center;

                img {
                    max-width: 100%;
                }
            }
        }

        .document-frame.mobile {
            width: 100%;
            margin: 0px 0px 0px 10px;

            .read-value-frame {
                width: 100%;
                padding: 7px 11px;
                margin-bottom: 0px;
                box-shadow: 0px 1px 0px #DBDBDB;

                .value-label {
                    font-size: 13px;
                    line-height: 130%;
                }
            }

            .read-value-frame.empty {
                display: none;
            }

            .document-image {
                width: 90%;
                margin: 10px 20px 10px 10px;
            }
        }
    }

    .document-delete-pane {
        .modal-pane-content {
            min-width: 350px;
            min-height: 250px;

            .modal-header {
                width: 80%;
                margin: 20px auto;
            }

            .modal-button-frame {
                left: 15px;
                right: 15px;
                bottom: 10px;
            }
        }
    }

    .document-renew-pane {
        .modal-pane-content {
            min-width: 350px;
            min-height: 295px;

            .full-screen-view {
                display: flex;
                flex-direction: column;

                .modal-toolbar {
                    position: relative;
                    width: 100%;
                    display: flex;
                    flex: 0 0 48px;
                    padding: 0px 10px;
                    align-items: center;
                    box-sizing: border-box;
                    justify-content: space-between;
                    box-shadow: 0px 1px 0px #DBDBDB;

                    .modal-toolbar-button {
                        position: relative;
                        z-index: 2;
                        font-size: 12px;
                        padding: 7px 16px;
                        font-weight: bold;
                        border-radius: 4px;
                    }

                    .bg-disabled, .bg-aqua {
                        color: white;
                        cursor: not-allowed;
                    }

                    .bg-aqua {
                        cursor: pointer;
                    }

                    .modal-toolbar-header {
                        position: absolute;
                        left: 0px;
                        right: 0px;
                        z-index: 0;
                        font-size: 16px;
                        line-height: 20px;
                        text-align: center;
                    }
                }

                .form-frame {
                    flex: 1 0;
                    padding: 20px;
                    display: flex;
                    flex-direction: column;

                    .key-value-frame {
                        margin-bottom: 21px;

                        .value-label {
                            font-size: 12px;
                            line-height: 160%;
                            margin-bottom: 4px;
                        }

                        .key-value-menu {
                            margin: 0px;
                            width: 100%;
                            height: 34px;
                            appearance: none;
                            background: none;
                            border-radius: 4px;
                            border: 1px solid #DBDBDB;

                            select {
                                width: 100%;
                                height: 100%;
                                outline: none;
                                font-size: 12px;
                                font-weight: 600;
                                box-sizing: border-box;
                                padding: 6px 24px 6px 10px;
                            }
                        }

                        .key-value-menu.bg-placeholder,
                        .key-value-textarea:placeholder-shown {
                            background: #FAFAFA;

                            select {
                                color: #919191;
                            }
                        }

                        .key-value-input {
                            width: 100%;
                            font-size: 12px;
                            font-weight: 600;
                            padding: 7px 10px;
                            border-radius: 4px;
                            box-sizing: border-box;
                            border: 1px solid #DBDBDB;
                        }

                        .key-value-file-input {
                            position: relative;
                            width: 100%;
                            height: 32px;
                            display: flex;
                            padding: 4px;
                            border-radius: 4px;
                            align-items: center;
                            box-sizing: border-box;
                            border: 1px solid #DBDBDB;
                            justify-content: space-between;

                            .file-input {
                                position: absolute;
                                top: 0px;
                                right: 0px;
                                bottom: 0px;
                                width: 80px;
                                opacity: 0;
                            }

                            .file-label {
                                flex: 1 0;
                                margin: 0px 6px;
                                font-size: 12px;
                                line-height: 15px;
                            }

                            .file-button {
                                height: 24px;
                                border: none;
                                color: white;
                                flex: 0 0 80px;
                                cursor: initial;
                                font-size: 10px;
                                font-weight: bold;
                                line-height: 130%;
                                border-radius: 2px;
                            }

                            .file-button.bg-grey-2 {
                                color: #2A2A2A;
                            }
                        }

                        .key-value-file-input.bg-placeholder {
                            .file-label {
                                color: #919191;
                            }
                        }
                    }

                    .key-value-frame.last {
                        margin: 0px;
                    }
                }
            }
        }
    }
}

.pac-logo::after {
    display: none !important;

    .pac-item {
        span {
            font-family: Avenir;
        }
    }
}