.centered-frame {
    width: 90%;
    margin: auto;
    max-width: 1200px;
}

.content-frame {
    position: absolute;
    top: 49px;
    left: 0px;
    right: 0px;
    bottom: 0px;
}

.full-screen-view {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
}

.layout-center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.hide {
    display: none;
}

.scroll {
    overflow-y: scroll;
}

.scroll::-webkit-scrollbar {
    width: 0px;
    display: none;
    background: transparent;
    -webkit-appearance: none;
    -moz-appearance: none;
}

// drag stuff
.handle {
    cursor: move;
}

// table related styling
.table-headers {
    width: 100%;
    height: 32px;
    background: #F2F2F2;
    border-bottom: 1px solid #DBDBDB;

    .table-header {
        position: relative;
        top: 6.5px;
        float: left;
        font-size: 12px;
        color: #484848;
    }
}

.capitalize {
    text-transform: capitalize;
}

button {
    padding: 0px;
    border: none;
    outline: none;
    cursor: pointer;
}