.roster-upload-view {
    .layout-center {
        display: flex;
        align-items: center;

        .progress-bar {
            width: 125px;
            height: 10px;
            overflow: hidden;
            border-radius: 8px;
            background: white;
            margin-right: 10px;

            .bg-red {
                height: 100%;
                transition: 0.5s;
            }
        }

        .progress-label {
            font-size: 21px;
            line-height: 100%;
        }
    }

    .toolbar {
        .save-button.disabled {
            color: #919191;
            cursor: not-allowed;
            background: #F2F2F2;
        }
    }

    .content-frame {
        .document-frame {
            width: 90%;
            display: flex;
            max-width: 980px;
            margin: 75px auto;
            align-items: center;
            flex-direction: column;

            .csv-icon {
                width: 60px;
                height: 67.5px;
                margin: 0px auto 35.5px auto;

                img {
                    width: 100%;
                    height: 100%;
                }
            }

            .csv-header {
                font-size: 24px;
                font-weight: bold;
                line-height: 30px;
                text-align: center;
            }

            .template-frame {
                display: flex;
                justify-content: center;
                margin: 20px 0px 44px 0px;

                .template-label,
                .template-link {
                    font-size: 14px;
                    line-height: 130%;
                }

                .template-link {
                    margin-left: 5px;
                }
            }

            .file-input-frame {
                position: relative;
                width: 85%;
                height: 36px;
                padding: 4px;
                display: flex;
                max-width: 450px;
                border-radius: 4px;
                align-items: center;
                background: #FAFAFA;
                box-sizing: border-box;
                border: 1px solid #DBDBDB;
                justify-content: space-between;

                .file-input {
                    position: absolute;
                    top: 0px;
                    right: 0px;
                    bottom: 0px;
                    z-index: 2;
                    opacity: 0;
                    width: 84px;

                    .csv-input {
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        margin: 0px;
                    }
                }

                .file-label {
                    font-size: 12px;
                    margin-left: 6px;
                    line-height: 15px;
                }

                .choose-file-button {
                    position: relative;
                    z-index: 1;
                    width: 80px;
                    height: 100%;
                    border: none;
                    color: white;
                    cursor: initial;
                    font-size: 10px;
                    font-weight: bold;
                    border-radius: 2px;
                    background: #008489;
                }
            }

            .hint-label {
                font-size: 16px;
                font-style: italic;
                line-height: 100%;
                margin: 48px 0px 36px 0px;
            }

            .section-frame {
                overflow: hidden;
                transition: 500ms;
                margin-bottom: 48px;

                .section-header-frame {
                    height: 53px;
                    display: flex;
                    padding: 0px 20px;
                    align-items: center;
                    background: #F2F2F2;
                    justify-content: space-between;

                    .section-header {
                        font-size: 24px;
                        font-weight: 600;
                        line-height: 100%;
                    }

                    .flex-frame {

                        .select-all-button,
                        .resize-button {
                            margin: 0px;
                            border: none;
                            outline: none;
                            font-size: 12px;
                            background: none;
                            padding: 7.5px 0px;
                        }

                        .select-all-button {
                            font-weight: bold;
                            margin-right: 30px;
                        }
                    }
                }

                .section-list-view {
                    column-count: 3;
                    padding: 10px;
                    list-style: none;
                    -webkit-column-count: 3;
                    margin: 7.5px 0px 0px 0px;

                    .section-cell {
                        position: relative;
                        display: flex;
                        cursor: pointer;
                        padding: 7.5px 0px;
                        align-items: center;
                        break-inside: avoid-column;
                        -webkit-column-break-inside: avoid;

                        .checkbox {
                            flex-grow: 0;
                            flex-shrink: 0;
                            flex-basis: 16px;
                        }

                        .section-label {
                            top: -2px;
                            font-size: 14px;
                            margin-left: 10px;
                            line-height: 15px;
                        }

                        .section-label.red {
                            font-size: 9px;
                            margin-left: 3px;
                        }
                    }
                }
            }

            .entries-frame {
                width: 100%;
                margin-top: 30px;

                .message {
                    font-size: 21px;
                    text-align: center;
                    margin-bottom: 30px;
                }

                .csv-table-view {
                    overflow: hidden;
                    border-radius: 4px;
                    border: 1px solid #DBDBDB;

                    .first-name {
                        width: 20.5%;
                        margin: 0px 1% 0px 2%;
                        text-transform: capitalize;
                    }

                    .last-name {
                        width: 22.5%;
                        margin: 0px 1% 0px 0px;
                        text-transform: capitalize;
                    }

                    .email {
                        width: 30%;
                        margin-right: 1%;
                    }

                    .role {
                        width: 21%;
                        margin-right: 1%;
                    }


                    .csv-list-view {
                        margin: 0px;
                        padding: 0px;
                        list-style: none;

                        .csv-cell {
                            height: 48px;
                            display: flex;
                            align-items: center;
                            border-bottom: 1px solid #DBDBDB;

                            .cell-label {
                                font-size: 12px;
                                overflow: hidden;
                                line-height: 15px;
                                white-space: nowrap;
                                text-overflow: ellipsis;
                            }

                            .red {
                                font-style: italic;
                            }
                        }

                        .csv-cell:last-child {
                            border: none;
                        }
                    }
                }
            }
        }
    }
}