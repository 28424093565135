.login-logo {
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;

    .logo {
        height: 100%;
    }

    .text {
        float: right;
        width: 48px;
        font-size: 12px;
        font-weight: 600;
        padding: 4.5px;
        color: #484848;
        line-height: 15px;
        text-align: center;
        border-radius: 4px;
        background: #F2F2F2;
    }
}
