.form-input-frame {
    position: relative;
    width: 30%;

    .form-input-header {
        font-size: 12px;
        margin-bottom: 4px;
    }

    .form-input {
        height: 36px;
        margin: 0px;
        width: 100%;
        font-size: 12px;
        border-radius: 4px;
        background: #FFFFFF;
        box-sizing: border-box;
        padding: 9px 2.5% 8px 2.5%;
        border: 1px solid #DBDBDB;
    }

    .form-input:disabled {
        cursor: not-allowed;
    }

    .form-input::placeholder {
        color: #484848;
    }

    .form-menu {
        position: relative;
        margin: 0px;
        width: 100%;
        height: 36px;
        font-size: 12px;
        appearance: none;
        border-radius: 4px;
        background: transparent;
        // padding: 9px 2.5% 8px 2.5%;
        // border: 1px solid #DBDBDB;

        .css-1n8qjyn-control {
            border: none;
            height: 100%;

            .css-1xxbl66, .css-1fo00wm {
                height: 100%;

                .css-6yg5gq-indicatorContainer {
                    padding-top: 6px;
                }
            }
        }
    }

    .form-menu.select {
        padding: 9px 2.5% 8px 2.5%;
    }

    .form-menu.hint {
        color: #484848;
    }

    .down-arrow-icon {
        position: absolute;
        top: 21px;
        right: 5px;
        z-index: 0;
        height: 34px;
        display: flex;
        align-items: center;

        img {
            width: 16px;
            height: 16px;
        }
    }
}