.bold {
    font-weight: 600;
}

.hidden {
    display: none;
}

.italic {
    font-style: italic;
}