.roster-checklist-view {
    .toolbar {
        display: flex;
        padding: 8px 10px;
        align-items: center;
        box-sizing: border-box;
        justify-content: space-between;

        .cancel-button {
            position: relative;
            top: auto;
            left: auto;
        }

        .print-button {
            display: flex;
            height: 32px;
            cursor: pointer;
            padding: 0px 6px;
            border-radius: 4px;
            align-items: center;
            background: #F2F2F2;

            img {
                width: 20px;
                height: 18px;
            }
        }
    }

    .content-frame {
        .logo-frame {
            display: flex;
            margin-top: 60px;
            padding-bottom: 45px;
            justify-content: space-between;
            border-bottom: 1px solid #DBDBDB;

            .company-logo, .una-logo {
                height: 46px;

                img {
                    height: 100%;
                }
            }
        }

        .profile-frame {
            display: flex;
            flex-wrap: wrap;
            padding: 24px 0px 50px 0px;
            justify-content: space-between;

            .section-frame {
                // width: 30%;
                flex: 0 0 30%;

                .section-header, .section-subvalue {
                    font-size: 16px;
                    line-height: 20px;
                }

                .section-value {
                    font-size: 24px;
                    font-weight: 600;
                    margin: 10px 0px 7px 0px;
                }

                .expand-button {
                    font-size: 16px;
                    margin: 0px;
                    padding: 0px;
                    border: none;
                    background: none;
                }
            }

            .signature-frame {
                display: flex;
                margin-top: 36px;
                flex-direction: column;

                .section-header, .section-value {
                    font-size: 16px;
                    line-height: 20px;
                }

                .section-value {
                    width: 250px;
                    padding-top: 2.5px;
                    border-top: 1px solid black;
                }

                .section-value.cursive {
                    border: none;
                    
                    font-size: 24px;
                    margin: 25px 0px 5px 0px;
                    font-family: 'Dancing Script', cursive;
                }
            }
        }

        .hint-label {
            font-size: 12px;
        }

        .scoring-key-frame {
            height: 60px;
            overflow: hidden;
            margin-top: 50px;
            transition: 0.3s;
            border-radius: 6px;
            box-sizing: border-box;
            border: 1px solid #DBDBDB;

            .header-frame {
                position: relative;
                display: flex;
                height: 60px;
                padding: 0px 2%;
                overflow: hidden;
                align-items: center;
                background: #FAFAFA;
                border-top-left-radius: 6px;
                border-top-right-radius: 6px;
                justify-content: space-between;
                border-bottom: 1px solid #DBDBDB;
                
                .header {
                    font-size: 16px;
                    line-height: 20px;
                    text-transform: uppercase;
                }

                .chevron-icon {
                    width: 24px;
                    height: 24px;
                    cursor: pointer;

                    img {
                        width: 100%;
                        height: 100%;
                        transition: 0.3s;
                    }
                }

                .chevron-icon.rotate {
                    img {
                        transform: rotate(-180deg);
                    }
                }
            }

            .scoring-keys-list-view {
                margin: 0px;
                padding: 0px;
                list-style: none;

                .scoring-key-cell {
                    height: 60px;
                    display: flex;
                    align-items: center;
                    padding: 0px 26px 0px 16px;
                    justify-content: space-between;
                    border-bottom: 1px solid #DBDBDB;

                    .value {
                        width: 36px;
                        height: 36px;
                        display: flex;
                        color: white;
                        font-size: 24px;
                        line-height: 30px;
                        border-radius: 6px;
                        align-items: center;
                        justify-content: center;
                    }
                }

                .scoring-key-cell:last-child {
                    border: none;
                }
            }
        }

        .categories-frame {
            margin-top: 50px;

            .header {
                font-size: 16px;
                line-height: 20px;
                margin-bottom: 15px;
                text-transform: uppercase;
            }

            .categories-list-view, .sections-list-view, .questions-list-view {
                margin: 0px;
                padding: 0px;
                list-style: none;

                .category-cell {
                    height: 65px;
                    overflow: hidden;
                    transition: 0.3s;
                    border-radius: 6px;
                    margin-bottom: 12px;
                    box-sizing: border-box;
                    border: 1px solid #DBDBDB;

                    .question-label {
                        font-size: 16px;
                        line-height: 130%;
                        white-space: normal;
                        word-wrap: break-word;
                    }

                    .category {
                        width: 100%;
                        padding: 20px 2%;
                        box-sizing: border-box;
                        border-bottom: 1px solid #DBDBDB;
                    }

                    .flex-frame {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;

                        .title {
                            overflow: hidden;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                        }

                        .progress-bar-view {
                            margin: 0px 16px 0px 20px;
                        }

                        .chevron-icon {
                            width: 24px;
                            height: 24px;
                            cursor: pointer;
                            
                            img {
                                width: 100%;
                                height: 100%;
                                transition: 0.3s;
                            }
                        }

                        .chevron-icon.rotate {
                            img {
                                transform: rotate(-180deg);
                            }
                        }
                    }
                }
            }

            .section-cell {
                height: 65px;
                overflow: hidden;
                transition: 0.3s;

                .section {
                    padding: 20px 4%;
                    border-bottom: 1px solid #DBDBDB;
                }

                .questions-list-view {
                    padding: 20px 4%;
                    border-bottom: 1px solid #DBDBDB;

                    .question-cell {
                        padding: 0px 40px;
                        margin-bottom: 23px;

                        .question-label {
                            margin-right: 20px;
                        }

                        .question-score {
                            flex: 0 0 36px;
                            width: 36px;
                            height: 36px;
                            display: flex;
                            color: white;
                            font-size: 24px;
                            border-radius: 6px;
                            align-items: center;
                            justify-content: center;
                        }
                    }
                }
            }

            .section-cell:last-child {
                .flex-frame {
                    border: none;
                }

                .questions-list-view {
                    border: none;
                }
            }
        }
    }
}

@media print {
    .roster-checklist-view {
        margin: 0px -20px;

        .toolbar {
            display: none;
        }

        .content-frame {
            position: initial;

            .logo-frame {
                margin-top: 30px;
            }

            .profile-frame {
                padding: 25px 0px;
                                
                .section-frame {
                    .section-header, .section-subvalue {
                        font-size: 14px;
                    }

                    .section-value {
                        font-size: 18px;
                    }

                    .expand-button {
                        display: none;
                    }
                }

                .signature-frame {
                    .section-header, .section-value {
                        font-size: 14px;
                    }

                    .section-value.cursive {
                        font-size: 24px;
                    }
                }
            }

            .scoring-key-frame {
                margin-top: 25px;
            }

            .hint-label {
                display: none;
            }

            .categories-frame {
                .categories-list-view, .sections-list-view, .questions-list-view {
                    .category-cell, .section-cell {
                        height: auto !important;

                        .chevron-icon {
                            display: none;
                        }
                    }

                    .question-cell {
                        page-break-inside: avoid;
                    }
                }
            }
        }
    }
}