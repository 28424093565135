.dashboard-roster-view {
  display: flex;
  flex-direction: column;

  .hover {
    cursor: help;
  }

  .stats-frame {
    width: 100%;
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 86px;
    padding: 30px 0px;
    border-bottom: 1px solid #dbdbdb;

    .centered-frame {
      display: flex;
      justify-content: space-between;

      .stat-frame {
        width: 30%;

        .header {
          font-size: 14px;
          color: #919191;
          line-height: 18px;
          text-align: center;
        }

        .stat-label {
          margin-top: 7px;
          font-size: 48px;
          color: #919191;
          line-height: 61px;
          text-align: center;
        }

        .stat-label.black {
          color: #484848;
        }
      }

      .stat-frame.middle {
        border-left: 1px solid #dbdbdb;
      }
    }
  }

  .toolbar-frame {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 48px;
    border-bottom: 1px solid #dbdbdb;

    .centered-frame {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .left-section {
        display: flex;

        .role-menu-frame {
          position: relative;
          height: 32px;
          display: flex;
          border-radius: 4px;
          margin-right: 10px;
          align-items: center;
          box-sizing: border-box;
          border: 1px solid #dbdbdb;

          .menu-label {
            position: absolute;
            left: 7.5px;
            font-size: 12px;
          }

          .role-menu {
            position: relative;
            width: 100%;
            height: 100%;
            margin: 0px;
            z-index: 1;
            border: none;
            outline: none;
            font-size: 12px;
            background: none;
            font-weight: 600;
            appearance: none;
            padding: 1px 15px 0px 40px;
          }

          .down-arrow-icon {
            position: absolute;
            top: 6.5px;
            right: 0px;
            z-index: 0;
            height: 17px;

            img {
              width: 16px;
              height: 16px;
            }
          }
        }

        .search-field,
        .search-field .search-field-input {
          font-size: 12px;
          background: white;
        }
      }

      .create-button,
      .upload-button {
        border: none;
        margin: 0px;
        padding: 5px;
        font-size: 12px;
        background: none;
      }

      .upload-button {
        margin-right: 20px;
      }
    }
  }

  .table-headers {
    height: auto;
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 23px;

    .centered-frame {
      height: 100%;
      display: flex;
      align-items: center;

      .table-header {
        top: 0px;
        width: 12%;
        overflow: hidden;
        line-height: 15px;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      .name {
        width: 18%;
      }

      .email {
        width: 19.5%;
      }

      .type {
        width: 13%;
        flex-grow: 1;
      }

      .status {
        width: 12%;
      }

      .sortable {
        cursor: pointer;
      }
    }
  }

  .roster-list-view {
    margin: 0px;
    flex-grow: 1;
    padding: 0px;
    list-style: none;

    .roster-cell {
      height: 48px;

      .centered-frame {
        height: 100%;
        display: flex;
        align-items: center;

        .flex-frame,
        .cell-label {
          width: 12%;
          font-size: 12px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        .flex-frame {
          display: flex;

          .progress {
            flex-grow: 1;

            .seperator {
              margin: 0px 2.5px;
            }
          }
        }

        .cell-label.hint {
          color: grey;
        }

        .name {
          width: 18%;
        }

        .type {
          width: 12%;
          flex-grow: 1;
          margin-right: 1%;
        }

        .email {
          width: 19.5%;
        }

        .status {
          width: 7%;
          text-transform: capitalize;
        }

        .name {
          display: flex;
          align-items: center;
          justify-content: space-around;

          .profile-picture {
            width: 36px;
            height: 36px;
            flex: 0 0 36px;
            overflow: hidden;
            border-radius: 50%;

            img {
              width: 100%;
              height: 100%;
            }
          }

          .cell-label {
            width: auto;
            flex-grow: 1;
            margin-left: 9px;
            padding-left: 1px;
          }
        }

        .expander-icon {
          width: 5%;
          height: 24px;
          display: flex;
          cursor: pointer;
          align-items: center;
          justify-content: center;

          img {
            width: 11px;
            height: 3px;
          }
        }
      }
    }

    .roster-cell:nth-child(even) {
      background: #fafafa;
    }
  }

  .loading-label {
    flex: 0 0 48px;
    display: flex;
    font-size: 12px;
    align-items: center;
    justify-content: center;
  }
}
