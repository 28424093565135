.dashboard-bundles-view {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    display: flex;
    flex-direction: column;
    -webkit-touch-callout: none; // iOS Safari
    -webkit-user-select: none; // Safari
    -khtml-user-select: none; // Konqueror HTML
    -moz-user-select: none; //Old versions of Firefox
    -ms-user-select: none; //Internet Explorer/Edge
    user-select: none; //Non-prefixed version, currently supported by Chrome, Opera and Firefox

    .filter-frame {
        width: 100%;
        flex: 0 0 48px;
        border-bottom: 1px solid #E8E8E8;

        .centered-frame {
            position: relative;

            .search {
                float: left;
                margin-top: 7.4px;
                margin-left: -1.5px;
                font-size: 12px;
                font-weight: 600;
                color: #484848;
                line-height: 15px;
                background: #FFFFFF;
                border: 1px solid #DBDBDB;
                box-sizing: border-box;
                border-radius: 4px;
                width: 20%;
                height: 32px;
                padding: 7px 10px;
                resize: none;
                white-space: pre-line;
            }

            .magnifying-glass {
                position: absolute;
                top: 14px;
                left: 6px;
                width: 16px;
                height: 16px;
            }

            .create-test-button {
                float: right;
                border: none;
                padding: 14.5px 0px 0 0;
                font-size: 12px;
                background: none;
                line-height: 15px;
            }
        }
    }

    .empty-label {
        opacity: 0.5;
        font-size: 21px;
        font-style: italic;
    }

    .content-frame {
        position: relative;
        top: 0px;
        flex: 1 1;
    }

    .bundles-table-view {
        top: auto;

        .table-headers {
            height: 30px;
            background: #F2F2F2;
            border-top: 1px solid #E8E8E8;
            border-bottom: 1px solid #E8E8E8;

            .centered-frame {
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;

                .table-header {
                    position: relative;
                    top: auto;
                    float: none;
                    width: 8%;
                    font-size: 12px;
                    overflow: hidden;
                    line-height: 15px;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }

                .title {
                    width: 25%;
                    flex: 0 0 25%;
                }

                .items, .date {
                    flex: 1 1;
                }
            }
        }

        .bundles-list-view {
            margin: 0px;
            padding: 0px;
            list-style: none;

            .bundle-cell {
                height: 36px;
                cursor: pointer;

                .centered-frame {
                    position: relative;
                    height: 36px;

                    .cell-content-frame {
                        position: relative;
                        z-index: 0;
                        height: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        .cell-label {
                            position: relative;
                            top: auto;
                            float: none;
                            font-size: 12px;
                            overflow: hidden;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            text-transform: capitalize;
                        }

                        .title {
                            width: 25%;
                            flex: 0 0 25%;
                        }

                        .items, .date {
                            flex: 1 1;
                        }
                    }
                }

                .expander-icon {
                    position: absolute;
                    top: 0px;
                    right: 0px;
                    width: 5%;
                    height: 100%;
                    display: flex;
                    cursor: pointer;
                    align-items: center;
                    justify-content: center;

                    img {
                        width: 11px;
                        height: 3px;
                    }
                }
            }

            .bundle-cell.disabled {
                cursor: not-allowed !important;
                background: #DDDDDD !important;
                border-bottom: 1px solid #E8E8E8;

                .cell-label {
                    color: #919191 !important;
                }
            }

            .bundle-cell:nth-child(even) {
                background: #FAFAFA;
            }
        }
    }
}

.menu-pane.bundle {
    .menu-content-view {
        margin-top: -34px !important;
    }
}